.carousel {
  height: 500px;
}
.carousel-item {
  transition: none;
}
img {
  max-height: 490px;
}

.glow {
  filter: drop-shadow(0px 0px 6px #ffffff);
}
